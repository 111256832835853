import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { it, enUS } from 'date-fns/locale'
// LOGIN RELATED 

import { MsalProvider, useMsal } from '@azure/msal-react';
import {setDefaultOptions} from "date-fns";



//END LOGIN
const App = ({ instance }) => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);
  if (navigator.language === 'it-IT') {
    setDefaultOptions({locale: it})
  } else {
    setDefaultOptions({locale: enUS})
  }
  
  return (
      <MsalProvider instance={instance}>
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
      </MsalProvider>

  );
};

export default App;
