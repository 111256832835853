import { createSlice } from '@reduxjs/toolkit';




const initialState = {
  campaignName: '',
  startDate: null,
  endDate: null,
  budgetCampaign: null,
  channelsList: [],
  planningAreaList: [],
  pdvsList: [],
  resultList: [],
  channelsAvailableList: [],
  geoSelected:null,
  loadingResultList: false,
  categoryList: [],
  availableCategoryList: [],
};

export const MerchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setBudgetCampaign: (state, action) => {
      state.budgetCampaign = action.payload;
    },
    setChannelsList: (state, action) => {
      state.channelsList = action.payload;
    },
    setPlanningAreaList: (state, action) => {
      state.planningAreaList = action.payload;
    },
    setPdvsList: (state, action) => {
      state.pdvsList = action.payload;
    },
    setResultList: (state, action) => {
      state.resultList = action.payload;
    },
    setChannelsAvailableList: (state, action) => {
      state.channelsAvailableList = action.payload;
    },
    setGeoSelected: (state, action) => {
      state.geoSelected = action.payload;
    },
    setLoadingResultList: (state, action) => {
      state.loadingResultList = action.payload;
    },
    setCategoryList:  (state, action) => {
      state.categoryList = action.payload;
    },
    setAvailableCategoryList:  (state, action) => {
      state.availableCategoryList = action.payload;
    },
  },
});

export const {
  setStartDate,
  setEndDate,
  setCampaignName,
  setChannelsList,
  setChannelsAvailableList,
  setPlanningAreaList,
  setPdvsList,
  setResultList,
  setBudgetCampaign, 
  setGeoSelected,
  setLoadingResultList,
  setCategoryList,
  setAvailableCategoryList,
} = MerchantSlice.actions;


export default MerchantSlice.reducer;
