export const apiRoutes = {
    ADD_USER: 'api/Dashboard/UserAccount',
    USER_DETAIL: 'api/Dashboard/MyProfile',
    USER_KPI: 'api/Dashboard/GetGroupKPIValues',
    USER_TYPE: 'api/Dashboard/UserAccount/{Type}',
    ADVERTISER_LIST: 'api/Dashboard/Advertisers',
    ADMIN_ACCOUNT_LIST: 'api/Dashboard/AdminAccount',
    ADVERTISER: 'api/Dashboard/Advertiser',
    AGENCY_LIST: 'api/Dashboard/Agencies',
    AGENCY: 'api/Dashboard/Agency',
    AGENCY_REFERRAL_LIST: 'api/Dashboard/AgenciesReferral',
    INSERTION_ORDER_LIST: 'api/Dashboard/CampaignInsertionOrders',
    INSERTION_ORDER_BYTYPE: 'api/Dashboard/CampaignInsertionOrders/{CampaignType}',
    PLATFORM_LIST: 'api/Dashboard/Platform',
    SALES_ACCOUNT_LIST: 'api/Dashboard/SalesAccount',
    TRAFFIC_ACCOUNT_LIST: 'api/Dashboard/TrafficAccount',
    SELL_TYPE_LIST: 'api/Dashboard/SellType',
    CATEGORY_TYPE: 'api/Dashboard/KPICategoryType',
    REPORT_ADMINISTRATION: 'api/Dashboardreport/GetAdministrationReport',
    REPORT_AIGEN: 'api/Dashboardreport/GetAigenReport',
    REPORT_MOBILITY: 'api/Dashboardreport/GetMobilityInsightsReport',
    REPORT_CAMPAIGN_PAGINATED: 'api/Dashboardreport/getEmbeddedPaginatedReport',
    REPORT_CAMPAIGN_NATIONAL: 'api/Dashboardreport/powerbi/custom',
    REPORT_LIST: 'api/Dashboard/CampaignInsertionOrdersReport',
    REPORT_DAILY: 'api/Dashboard/CampaignDaily/{insertionOrderId}',
    REPORT_DOWNLOAD: 'api/Dashboardreport/exportReport/{insertionOrderId}/{lang}',
    CAMPAIGN_ACTIVATE: 'api/Dashboard/ActivateInsertionOrder',
    CAMPAIGN_LIST_ALL: 'api/Dashboard/CampaignInsertionOrdersAll',
    CAMPAIGN_LIST_MONITOR_SALE: 'api/Dashboard/CampaignInsertionOrdersforSales',
    CAMPAIGN_LIST_STATUS: 'api/Dashboard/CampaignInsertionOrderStatus',
    CAMPAIGN_INSIGHT_ATTACHMENT: 'api/Dashboard/CampaignAttachment/{InsertionOrderId}',
    CAMPAIGN_INSIGHT_PLATFORM: 'api/Dashboard/CampaignInsightsPlatform/{insertionOrderId}',
    CAMPAIGN_INSIGHT_DETAILS: 'api/Dashboard/CampaignInsertionOrderDetail/{insertionOrderId}',
    CAMPAIGN_INSIGHT_CLIENT: 'api/Dashboard/ClientKPI/{insertionOrderId}',
    CAMPAIGN_INSIGHT_HICMOBILE: 'api/Dashboard/HicMobileKPI/{insertionOrderId}',
    CAMPAIGN_INSIGHT_SCREENS: 'api/Dashboard/CampaignScreens/{insertionOrderId}',
    CAMPAIGN_INSIGHT_SCREENS_SEND_MAIL: 'api/Dashboard/SendScreenshotsMail/{insertionOrderId}',
    CAMPAIGN_INSIGHT_SCREENS_ADD_DELETE: 'api/Dashboard/CampaignScreen/{CampaignContentId}',
    CAMPAIGN_INSIGHT_DAILY_NOTE: 'api/Dashboard/ClientDaily/{insertionOrderId}',
    CAMPAIGN_INSIGHT_DAILY_NOTE_UPDATE: 'api/Dashboard/ClientDailyNote/{id}',
    CAMPAIGN_INSIGHT_DELIVERY_NOTE: 'api/Dashboard/DeliveryNotes/{insertionOrderId}',
    CAMPAIGN_INSIGHT_DELIVERY_NOTE_UPDATE: 'api/Dashboard/DeliveryNotes',
    CAMPAIGN_INSIGHT_PUBLISH: 'api/Dashboard/PublishKPI/{Id}',
    CAMPAIGN_INSIGHT_PUBLISH_ALL: 'api/Dashboard/PublishAllKPI/{Id}',
    CAMPAIGN_INSIGHT_TOP_PUBLISHERS : 'api/Dashboard/TopPublisher/{insertionOrderId}',
    CAMPAIGN_SENDREPORT: 'api/Dashboard/SendCampaignReportMail',
    KPI_CATEGORY_TYPE: 'api/Dashboard/KPICategoryType',
    ADD_EDIT_KPI_CLIENT: 'api/Dashboard/ClientKPI',
    FILE_LIST: 'api/Dashboard/SalesResources/{SalesResourceId}',
    FOOTFALL_FILE: 'api/Dashboard/LoadFootfallExcel/{insertionOrderId}',
    FOOTFALL_KPI: 'api/Dashboard/FootfallCampaignKPI/{insertionOrderId}',
    FOOTFALL_PDV: 'api/Dashboard/LoadFootfallPdvPrecampaign/{insertionOrderId}',
    FOOTFALL_POWERPOINT: 'api/Dashboard/LoadFootfallPresentation/{insertionOrderId}',
    PRECAMPAIGN_CREATE: 'api/Dashboard/RequestPreCampaign',
    PRECAMPAIGN_PRESENTATION: 'api/Dashboard/PreCampaignPresentation/{PreCampaignId}',
    PRECAMPAIGN_LIST: 'api/Dashboard/PreCampaigns',
    PRECAMPAIGN_KPI: 'api/Dashboard/PreCampaignKPI/{preCampaignId}/{kpiCategory}',
    PRECAMPAIGN_KPI_UPDATE: 'api/Dashboard/UpdatePreCampaignKPI/{PreCampaignId}',
    PRECAMPAIGN_AUDIENCE_CATEGORY: 'api/Dashboard/AudienceCategory/{AudienceCategoryId}',
    PRECAMPAIGN_AUDIENCE_CATEGORY_LOAD: 'api/Dashboard/LoadAudienceCategorySegment',
    MERCHANT_ALL_CHANNEL: 'api/Merchant/AllChannel',
    MERCHANT_INFO: 'api/Merchant/MerchantsbyType',
    MERCHANT_EVALUATE: 'api/Merchant/EvaluateBudget',
    MERCHANT_CATEGORIES: 'api/Merchant/AllCategories',
    MERCHANT_INSIGHTS: 'api/Merchant/GetMerchantsInsights',
    LOCATIONS: 'api/Dashboard/SearchDfpLocations/{searchText}',
    LOCATIONS_DFP: 'api/DashboardDfp/SearchDfpLocations/{searchText}',
    GEOCODEADDRESS: 'api/Dashboard/GeocodeAddress/{address}',
    GETINSIGHTS_RADIUS : 'api/Dashboard/GetInsightRadius/{latitude}/{longitude}',
    TARGETFINDER_BUSSINESSCATEGORY : 'api/TargetFinder/suggestCategory/{query}',
    TARGETFINDER_MINMAX: 'api/TargetFinder/MinAndMaxBudget',
    TARGETFINDER_GET: 'api/TargetFinder/GetTargetInformation',
    TARGETFINDER_UPDATE: 'api/TargetFinder/TargetFinderStrategicPlan',
    TARGETFINDER_MEDIA: 'api/TargetFinder/MediaType',
    TARGETFINDER_SELLTYPE: 'api/TargetFinder/SellType',
    TARGETFINDER_SELLTYPEMINMAX: 'api/TargetFinder/GetSellTypeValueMinMax',
    TARGETFINDER_MINMAXSELLTYPE: 'api/TargetFinder/MinAndMaxSellTypeValue',
    TARGETFINDER_REPORTS: 'api/TargetFinder/GetListStrategicPlan',
    TARGETFINDER_GETSINGLE: 'api/TargetFinder/GetSummaryList/{Id}',
    TARGETFINDER_BRIEF: 'api/TargetFinder/StrategicReport/{Id}',

};