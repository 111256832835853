import { createSlice } from '@reduxjs/toolkit';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { MRT_Localization_EN } from 'material-react-table/locales/en';





const initialState = {
  activeDir: 'ltr',
    activeMode: localStorage.getItem('themeMode') ?? 'light',  // This can be light or dark
  activeTheme: 'BLUE_THEME', // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth: 270,
  MiniSidebarWidth: 87,
  TopbarHeight: 70,
    isLayout: localStorage.getItem('themeLayout') ?? 'boxed', // This can be full or boxed
    isCollapse: localStorage.themeSidebar === 'true' ? true : false , // to make sidebar Mini by default
  isSidebarHover: false,
  isMobileSidebar: false,
  isHorizontal: false,
  isLanguage: localStorage.getItem('isLanguage') ?? 'en',
  isCardShadow: true,
  borderRadius: 7,
  userName: [] ,
  token: '',
  userKPI: undefined,
  localizationTable: localStorage.getItem('isLanguage') === 'en' ? MRT_Localization_EN : MRT_Localization_IT,
  defaultFilterTraffic: localStorage.getItem('defaultFilterTraffic') ?? ''
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.activeTheme = action.payload;
    },
    setDarkMode: (state, action) => {
        state.activeMode = action.payload;
        localStorage.setItem('themeMode', action.payload);
    },
    setDir: (state, action) => {
      state.activeDir = action.payload;
    },
      setLanguage: (state, action) => {
      localStorage.setItem('isLanguage', action.payload);
      state.isLanguage = action.payload;
      if (action.payload === 'en')
        state.localizationTable = MRT_Localization_EN;
      else
        state.localizationTable = MRT_Localization_IT;
      },    
    setCardShadow: (state, action) => {
      state.isCardShadow = action.payload;
    },
    toggleSidebar: (state) => {
        state.isCollapse = !state.isCollapse;
        localStorage.setItem('themeSidebar', state.isCollapse);
    },
    hoverSidebar: (state, action) => {
      state.isSidebarHover = action.payload;
    },
    toggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
    toggleLayout: (state, action) => {
        state.isLayout = action.payload;
        localStorage.setItem('themeLayout', action.payload);
    },
    toggleHorizontal: (state, action) => {
      state.isHorizontal = action.payload;
    },
    setBorderRadius: (state, action) => {
      state.borderRadius = action.payload;
    },
    getuserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserKPI: (state, action) => {
      state.userKPI = action.payload;
    },

    getLocalizationTable: (state, action) => {
      state.localizationTable =  action.payload;    },
    setDefaultFilterTraffic: (state, action) => {
      state.defaultFilterTraffic = action.payload;
      localStorage.setItem('defaultFilterTraffic', action.payload);
    }
  }
});

export const {
  setTheme,
  setDarkMode,
  setDir,
  toggleSidebar,
  hoverSidebar,
  toggleMobileSidebar,
  toggleLayout,
  setBorderRadius,
  toggleHorizontal,
  setLanguage,
  setCardShadow,
  getuserName,
  setUserKPI,
  getLocalizationTable,
  setDefaultFilterTraffic
} = CustomizerSlice.actions;


export default CustomizerSlice.reducer;
