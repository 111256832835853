import { createSlice } from '@reduxjs/toolkit';




const initialState = {
    platformList: [],
    kpiList: [],
    sellTypeList: [],
    agencyList: [],
    agencyReferralList: [],
    advertiserList: [],
    salesList: [],
    trafficList: [],
    advertiserReferralList: [],
    activeOrders: [],
    scheduledOrders: [],
    closedOrders: [],
    controlOrders: [],
    controlFilters : [],
    controlSearch: '',
    controlUser: 'Team'

};

export const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setPlatformList: (state, action) => {
            state.platformList = action.payload;
        },
        setKpiList: (state, action) => {
            state.kpiList = action.payload;
        },
        setSellTypeList: (state, action) => {
            state.sellTypeList = action.payload;
        },
        setAgencyList: (state, action) => {
            state.agencyList = action.payload;
        },
        setAgencyReferralList: (state, action) => {
            state.agencyReferralList = action.payload;
        },
        setAdvertiserList: (state, action) => {
            state.advertiserList = action.payload;
        },
        setSalesList: (state, action) => {
            state.salesList = action.payload;
        },
        setTrafficList: (state, action) => {
            state.trafficList = action.payload;
        },
        setAdvertiserReferralList: (state, action) => {
            state.advertiserReferralList = action.payload;
        },
        setActiveOrders: (state, action) => {
            state.activeOrders = action.payload;
        },
        setScheduledOrders: (state, action) => {
            state.scheduledOrders = action.payload;
        },
        setClosedOrders: (state, action) => {
            state.closedOrders = action.payload;
        },
        setControlOrders: (state, action) => {
            state.controlOrders = action.payload;
        },
        setControlFilters: (state, action) => {
            //add item to the list or toggle
            const index = state.controlFilters.findIndex((x) => x === action.payload);
            if (index === -1) {
                state.controlFilters.push(action.payload);
            } else {
                state.controlFilters.splice(index, 1);
            }
        },
        setControlSearch: (state, action) => {
            state.controlSearch = action.payload;
        },
        setControlUser: (state, action) => {
            state.controlUser = action.payload;
        }
        
    },
});

export const {
    setPlatformList,
    setKpiList,
    setSellTypeList,
    setAgencyList,
    setAgencyReferralList,
    setAdvertiserList,
    setSalesList,
    setTrafficList,
    setAdvertiserReferralList,
    setActiveOrders,
    setScheduledOrders,
    setClosedOrders,
    setControlOrders,
    setControlFilters,
    setControlSearch,
    setControlUser
} = OrderSlice.actions;


export default OrderSlice.reducer;
