import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import OrderReducer from './order/OrderSlice';
import MerchantReducer from './merchant/MerchantSlice';
import TargetFinderReducer from './targetFinder/TargetFinderSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    order: OrderReducer,
    merchant: MerchantReducer,
    targetFinder: TargetFinderReducer,
  },
});



export default store;
