import { createSlice } from '@reduxjs/toolkit';
import brand0 from "../../assets/images/raster/brand.jpg";
import brand1 from "../../assets/images/raster/brand2.jpg";
import brand2 from "../../assets/images/raster/brand3.jpg";
import identityImage from "../../assets/images/raster/Drivetostore.jpg";
import riconoscibilita from "../../assets/images/raster/OnlineEngagement.png";
import riconoscibilita2 from "../../assets/images/raster/OnlineEngagement2.png";
import riconoscibilita3 from "../../assets/images/raster/OnlineEngagement3.png";
import target1 from '../../assets/images/svgs/targetOne.svg';
import target2 from '../../assets/images/svgs/targetTwo.svg';
import target3 from '../../assets/images/svgs/targetThree.svg';
import drive0 from "../../assets/images/raster/drive2store.jpg";
import drive1 from "../../assets/images/raster/drive2store1.jpg";
import drive2 from "../../assets/images/raster/drive2store3.jpg";


const onlineEngagement = [ riconoscibilita, riconoscibilita2, riconoscibilita3];
const brandAwareness = [ brand2, brand1, brand0];
const drive2store = [ drive0, drive1, drive2];

const initialState = {
  campaignName: '',
  agency:'',
  startDate: null,
  endDate: null,
  budgetCampaign: null,
  brandName: null,
  subBrandName: null,
  competitor: null,
  product: null,
  productLink: null,
  category : [],
  mediaType: '',
  sellType: '',
  sellTypeCost: null,
  targetIdea: false,
  target: '',
  locationType: null,
  selectedLocations: [],
  pdvNumbers: 1,
  hyperlocalFile: null,
  gptResponse: {},
  gptEditedResponse: null,
  gptIndex: 0,
  mediaTypeList : [],
  hasDN: 'false',
  sellTypeList: [],
  minMaxSellType: null,
  tempId: "b102d104-b4f1-484c-b6c7-5c68e0b0d99f",
  kpiDescription: null,
  strategyObjective: [],
  contentText : [
    {id:"Brand Awareness", title: "Brand Awareness", icon: target1 , image: brandAwareness, description: "Rafforzare la visibilità e il riconoscimento del brand presso un vasto pubblico, utilizzando targeting avanzato e formati pubblicitari efficaci"},
    {id:"Drive-to-Store", title:"Drive2Store",  icon: target2 , image:drive2store , description:"Incentivare le visite ai punti vendita fisici collegando la pubblicità mobile al traffico locale, spingendo gli utenti a interagire direttamente con il brand"},
    {id:"Online Engagement", title:"Online Engagement", icon:target3  , image:onlineEngagement , description:"Stimolare interazioni online da parte di utenti qualificati, ottimizzando l'engagement su app e piattaforme digitali"},
  ],
};

export const TargetFinderSlice = createSlice({
  name: 'targetFinder',
  initialState,
  reducers: {
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setAgency: (state, action) => {
      state.agency = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setBudgetCampaign: (state, action) => {
      state.budgetCampaign = action.payload;
    },
    setBrandName: (state, action) => {
      state.brandName = action.payload;
    },
    setSubBrandName: (state, action) => {
      state.subBrandName = action.payload;
    },
    setCompetitor: (state, action) => {
      state.competitor = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProductLink: (state, action) => {
      state.productLink = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setMediaType: (state, action) => {
      state.mediaType = action.payload;
    },
    setSellType: (state, action) => {
      state.sellType = action.payload;
    },
    setSellTypeCost: (state, action) => {
      state.sellTypeCost = action.payload;
    },
    setTargetIdea: (state, action) => {
      state.targetIdea = action.payload;
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    setLocationType: (state, action) => {
      state.locationType = action.payload;
    },
    setSelectedLocations: (state, action) => {
      state.selectedLocations = action.payload;
    },
    setHyperlocalFile: (state, action) => {
      state.hyperlocalFile = action.payload;
    },
    setGptResponse: (state, action) => {
      state.gptResponse = action.payload;
    },
    setGptEditedResponse: (state, action) => {
      state.gptEditedResponse = action.payload;
    },
    setGptIndex: (state, action) => {
      state.gptIndex = action.payload;
    },
    setMediaTypeList: (state, action) => {
      state.mediaTypeList = action.payload;
    },
    setSellTypeList: (state, action) => {
      state.sellTypeList = action.payload;
    },
    setMinMaxSellType: (state, action) => {
      state.minMaxSellType = action.payload;
    },
    setHasDN: (state, action) => {
      state.hasDN = action.payload;
    },
    setPdvNumbers: (state, action) => {
      state.pdvNumbers = action.payload;
    },
    setTempId: (state, action) => {
      state.tempId = action.payload;
    },
    setKpiDescription: (state, action) => {
      state.kpiDescription = action.payload;
    },
    setStrategyObjective: (state, action) => {
      state.strategyObjective = action.payload
    },
  },
});

export const {
  setStartDate,
  setAgency,
  setEndDate,
  setCampaignName,
  setBudgetCampaign,
  setBrandName,
  setSubBrandName,
  setCompetitor,
  setProduct,
  setProductLink,
  setCategory,
  setMediaType,
  setSellType,
  setSellTypeCost,
  setTargetIdea,
  setTarget,
  setLocationType,
  setSelectedLocations,
  setHyperlocalFile,
  setGptResponse,
  setGptEditedResponse,
  setGptIndex,
  setMediaTypeList,
  setSellTypeList,
  setMinMaxSellType,
  setHasDN,
  setPdvNumbers,
  setTempId,
  setKpiDescription,
  setStrategyObjective
  
  
} = TargetFinderSlice.actions;


export default TargetFinderSlice.reducer;
