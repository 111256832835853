import * as React from 'react';
import {
    Button,
    Dialog,
    Fab,
    DialogContent,
    TextField,
    DialogActions,
    DialogContentText, Snackbar, Alert,
    Typography, DialogTitle, Grid, InputAdornment, IconButton, MenuItem, FormHelperText, Autocomplete, Select,
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import CustomFormLabel from '../../../components/forms/theme-elements/CustomFormLabel';
import CustomOutlinedInput from '../../../components/forms/theme-elements/CustomOutlinedInput';
import {IconBuildingArch, IconMail, IconMessage2, IconPhone, IconUser, IconKey} from '@tabler/icons';
import {useEffect} from "react";
import {protectedResources} from "../../../authConfig";
import useFetchWithMsal from "../../../hooks/useFetchWithMsal";
import * as yup from 'yup';
import { Form, useFormik } from "formik";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {Euro} from "@mui/icons-material";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from "moment/moment";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";



const AddEditOrder = ({open, close, source, defaultSaleAccount}) => {
    const { t } = useTranslation();
    const order = useSelector((state) => state.order);

    const {agencyList, agencyReferralList, advertiserList, salesList, sellTypeList, trafficList, advertiserReferralList} = order;

    const validationSchema = yup.object({
        CampaignInsertionOrderName: yup
            .string()
            .min(2, t('Too Short!'))
            .max(50, t('Too Long!'))
            .required(t('Field is Required')),
        CampaignInsertionOrderStarDate: yup.date('Date is invalid').required(t('Field is Required')),
        CampaignInsertionOrderEndDate: yup.date('Date is invalid').required(t('Field is Required')),
        AgencyId: yup.string().required(t('Field is Required')).nullable(),
        AgencyReferralEmails: yup.array().min(1).required(t('Field is Required')).nullable(),
        AdvertiserId: yup.string().required(t('Field is Required')).nullable(),
        CampaignInsertionOrderSalesInternalCode: yup.string().required(t('Field is Required')),
        CampaignInsertionOrderTarget: yup.string().required(t('Field is Required')),
        CampaignInsertionOrderAmount: yup.number().integer().required(t('Field is Required')),
        SalesAccountEmail: yup.string().required(t('Field is Required')).nullable(),
        SellTypeId: yup.string().required(t('Field is Required')).nullable(),
        CampaignInsertionOrderSellTypeValue: yup.number().min(0).required(t('Field is Required')).nullable(),
        CampaignInsertionOrderTotalBudget: yup.number().required(t('Field is Required')),
        // vieability percentage required if sell type is CPV        
        ViewabilityPercentage: yup.number().when('SellTypeId', {
            is: SellTypeId=> sellTypeList.filter(x => x.SellTypeId === SellTypeId)[0]?.SellTypeName === 'CPV',
            then: yup.number().max(100).required(t('Field is Required')),
            otherwise: yup.number().nullable()
        }),
        CampaignInsertionOrderPdvNumber: yup.string().when('CampaignInsertionOrderLocation', {
            is: CampaignInsertionOrderLocation => CampaignInsertionOrderLocation === 'Hyper-Local',
            then: yup.string().required(t('Field is Required')),
            otherwise: yup.string().nullable()
        }),
          

    });
    const [snackbarOpened, setSnackbarOpened] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('error');
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpened(false);
    };
    const user = useSelector((state) => state.customizer);
    const { userName } = user;
    const [defaultAgency, setDefaultAgency] = React.useState(null);
    const [defaultAgencyReferralEmail, setDefaultAgencyReferralEmail] = React.useState([]);
    const [defaultAdvertiser, setDefaultAdvertiser] = React.useState(null);
    const [defaultAdvertiserReferralEmails, setDefaultAdvertiserReferralEmails] = React.useState([]);
    const [defaultSalesAccountEmail, setDefaultSalesAccountEmail] = React.useState(null);
    const [defaultSellType, setDefaultSellType] = React.useState(null);
    const [defaultTrafficEmail, setDefaultTrafficEmail] = React.useState(null);
    const formik = useFormik({
        initialValues: {
            CampaignInsertionOrderId: null,
            CampaignInsertionOrderName: '',
            AgencyId: null,
            SalesAccountEmail: userName?.UserEmail,
            CampaignInsertionOrderLocation: 'Hyper-Local',
            CampaignInsertionOrderState: 'Italia',
            CampaignInsertionOrderDays: 'All',
            CampaignInsertionOrderTelcoOperators: 'All',
            CampaignInsertionOrderOperatingSystems: 'All',
            CampaignInsertionOrderSalesInternalCode: '',
            CampaignInsertionOrderDeviceTypes: 'All',
            CampaignInsertionOrderTarget: 'All',
            CampaignInsertionOrderTotalBudget: '',
            SellTypeId: '',
            CampaignInsertionOrderSellTypeValue: null,
            ViewabilityPercentage: null,
            CampaignInsertionOrderPdvNumber: null,
            CampaignInsertionOrderAmount: '',
            CampaignInsertionOrderStarDate: moment(new Date()).utcOffset(0, true).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).toDate(),
            CampaignInsertionOrderEndDate: moment(new Date()).utcOffset(0, true).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).toDate(),
            AgencyReferralEmails: [],
            AdvertiserId: null,
            CampaignInsertionOrderNote: '',
            CampaignInsertionOrderInvoiceNote: '',
            CampaignInsertionOrderKPIGoal: '',
            TrafficEmail: 'giovanni.strada@hicmobile.com',
            CampaignInsertionOrderActive: false,
            AdvertiserReferralEmails: [],
            CampaignInsertionOrderDeliveryNotes: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            if (sellTypeList.filter(x => x.SellTypeId === formik.values.SellTypeId)[0].SellTypeName !== 'CPV') {
                formik.setFieldValue("ViewabilityPercentage", null);
                values.ViewabilityPercentage = null;
            }
            if (formik.values.CampaignInsertionOrderLocation !== 'Hyper-Local') {
                formik.setFieldValue("CampaignInsertionOrderPdvNumber", null);
                values.CampaignInsertionOrderPdvNumber = null;
            }            
            execute("POST", protectedResources.apiInsertionOrderList.endpoint, values).then((response) => {
                if (response.Status === 500) {
                    setSnackbarMessage('Error');
                    setSnackbarSeverity('error');
                    setSnackbarOpened(true);
                    close();
                }else {
                    setSnackbarMessage(response.CustomError.split('|').toString());
                    setSnackbarSeverity('success');
                    setSnackbarOpened(true);
                    close();
                }
            }).catch((e) => {
                console.log(e);
                setSnackbarMessage('Error');
                setSnackbarSeverity('error');
                setSnackbarOpened(true);
                close();
            });
        },
    });


    const handleClose = () => {
        open = false;
    };


    const [agency, setAgency] = React.useState(null);
    const [agencies, setAgencies] = React.useState([]);
    const [saleDefaultValue, setSaleDefaultValue] = React.useState(null);
    const [saleAccounts, setSaleAccounts] = React.useState([]);
    const handleChange2 = (event) => {
        setAgency(event.CampaignInsertionOrderTarget.value);
    };


    const {error, execute, isLoading} = useFetchWithMsal({
        scopes: protectedResources.agencyReferral.scopes.read,
    });
    useEffect(() => {     
        formik.resetForm();
        formik.setFieldValue("SalesAccountEmail", userName?.UserEmail);
        //setDefaultAgency(null);
        if (Object.keys(source).length > 0) {
            formik.setFieldValue("CampaignInsertionOrderName", source.CampaignInsertionOrderName);
            formik.setFieldValue("CampaignInsertionOrderStarDate", source.CampaignInsertionOrderStarDate);
            formik.setFieldValue("CampaignInsertionOrderEndDate", source.CampaignInsertionOrderEndDate);
            formik.setFieldValue("AgencyId", source.AgencyId);
            setDefaultAgency({
                id: source.AgencyId,
                label: agencyList.filter(x => x.AgencyId == source.AgencyId)[0].AgencyName
            });
            formik.setFieldValue("AgencyReferralEmails", source.AgencyReferralEmail);
            setDefaultAgencyReferralEmail(source.AgencyReferralEmail.map(x => ({ id: x, label: x })));
            formik.setFieldValue("AdvertiserId", source.AdvertiserId)
            setDefaultAdvertiser({
                id: source.AdvertiserId,
                label: advertiserList.filter(x => x.AdvertiserId == source.AdvertiserId)[0].AdvertiserName
            });
            formik.setFieldValue("SalesAccountEmail", source.SalesAccountEmail);
     //       setDefaultSalesAccountEmail({ id: source.SalesAccountEmail, label: source.SalesAccountEmail });
            setDefaultTrafficEmail({ id: source.TrafficEmail, label: source.TrafficEmail });
            formik.setFieldValue("TrafficEmail", source.TrafficEmail);
            formik.setFieldValue("CampaignInsertionOrderSalesInternalCode", source.CampaignInsertionOrderSalesInternalCode);
            formik.setFieldValue("CampaignInsertionOrderTarget", source.CampaignInsertionOrderTarget);
            formik.setFieldValue("CampaignInsertionOrderAmount", source.CampaignInsertionOrderAmount);
            formik.setFieldValue("CampaignInsertionOrderNote", source.CampaignInsertionOrderNote);
            formik.setFieldValue("CampaignInsertionOrderInvoiceNote", source.CampaignInsertionOrderInvoiceNote);
            formik.setFieldValue("CampaignInsertionOrderId", source.CampaignInsertionOrderId);
            formik.setFieldValue("CampaignInsertionOrderLocation", source.CampaignInsertionOrderLocation ?? 'Hyper-Local');
            formik.setFieldValue("CampaignInsertionOrderState", source.CampaignInsertionOrderState);
            formik.setFieldValue("CampaignInsertionOrderDays", source.CampaignInsertionOrderDays);
            formik.setFieldValue("CampaignInsertionOrderTelcoOperators", source.CampaignInsertionOrderTelcoOperators);
            formik.setFieldValue("CampaignInsertionOrderOperatingSystems", source.CampaignInsertionOrderOperatingSystems);
            formik.setFieldValue("CampaignInsertionOrderDeviceTypes", source.CampaignInsertionOrderDeviceTypes);
            formik.setFieldValue("CampaignInsertionOrderTotalBudget", source.CampaignInsertionOrderTotalBudget);
            formik.setFieldValue("ViewabilityPercentage", source.ViewabilityPercentage);
            formik.setFieldValue("CampaignInsertionOrderPdvNumber", source.CampaignInsertionOrderPdvNumber);
            formik.setFieldValue("SellTypeId", source.SellTypeId);
            setDefaultSellType({
                id: source.SellTypeId,
                label: sellTypeList.filter(x => x.SellTypeId === source.SellTypeId)[0].SellTypeName
            });
            formik.setFieldValue("CampaignInsertionOrderSellTypeValue", source.CampaignInsertionOrderSellTypeValue);
            formik.setFieldValue("CampaignInsertionOrderAmount", source.CampaignInsertionOrderAmount);
            formik.setFieldValue("CampaignInsertionOrderActive", source.CampaignInsertionOrderActive);
            formik.setFieldValue("AdvertiserReferralEmails", source.AdvertiserReferralEmails);
            formik.setFieldValue("CampaignInsertionOrderDeliveryNotes", source.CampaignInsertionOrderDeliveryNotes);
            setDefaultAdvertiserReferralEmails(source.AdvertiserReferralEmails.map(x => ({ id: x, label: x })));

        } else {
            setDefaultAgency(null);
            setDefaultAgencyReferralEmail([]);
          //  setDefaultSalesAccountEmail(null);
            setDefaultAdvertiserReferralEmails([]);
            setDefaultAdvertiser(null);
            setDefaultTrafficEmail(
                {
                    id: "giovanni.strada@hicmobile.com", label: "giovanni.strada@hicmobile.com"
                });
            setDefaultSellType(null);
        }
    }, [open]);


    return (
        <>
            <Snackbar open={snackbarOpened} autoHideDuration={10000} onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} variant="filled"  severity={snackbarSeverity} sx={{  backgroundColor: snackbarSeverity === 'error'? '':'teal', width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
 
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={formik.handleSubmit}>

                    <DialogTitle> {Object.keys(source).length === 0 ? t("Add New Order") : t("Edit Order")} </DialogTitle>
                    <DialogContent>

                        <Grid container>
                           
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderName" sx={{mt: 0}}>
                                    {t('Campaign Name')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconUser size="20"/>
                                        </InputAdornment>
                                    }
                                    id="CampaignInsertionOrderName"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderName && Boolean(formik.errors.CampaignInsertionOrderName)}

                                />
                                <FormHelperText error id="CampaignInsertionOrderName-error">
                                    {formik.touched.CampaignInsertionOrderName && formik.errors.CampaignInsertionOrderName}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderStarDate">
                                   { t('Start Date')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>

                                    <DatePicker
                                        renderInput={(props) => <CustomTextField {...props} fullWidth size="small" sx={{
                                            '& .MuiSvgIcon-root': {
                                                width: '18px',
                                                height: '18px',
                                            },
                                            '& .MuiFormHelperText-root': {
                                                display: 'none',
                                            },
                                        }}

                                         error={formik.touched.CampaignInsertionOrderStarDate && Boolean(formik.errors.CampaignInsertionOrderStarDate)}
                                        />}
                                        id="CampaignInsertionOrderStarDate"
                                        value={formik.values.CampaignInsertionOrderStarDate}
                                        placeholder="DateTimePicker"
                                        onChange={(value) => {
                                            formik.setFieldValue('CampaignInsertionOrderStarDate', moment(value).utcOffset(0, true).set({
                                                hour: 0,
                                                minute: 0,
                                                second: 0,
                                                millisecond: 0
                                            }).toDate() ?? null);
                                        }}

                                    />
                                </LocalizationProvider>


                                <FormHelperText error id="lastName-error">
                                    {formik.touched.CampaignInsertionOrderStarDate && formik.errors.CampaignInsertionOrderStarDate}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderEndDate">
                                   { t('End Date')}
                                </CustomFormLabel>
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <CustomTextField {...props} fullWidth size="small" sx={{
                                            '& .MuiSvgIcon-root': {
                                                width: '18px',
                                                height: '18px',
                                            },
                                            '& .MuiFormHelperText-root': {
                                                display: 'none',
                                            },
                                        }}
                                        error={formik.touched.CampaignInsertionOrderEndDate && Boolean(formik.errors.CampaignInsertionOrderEndDate)}
                                        />}
                                        value={formik.values.CampaignInsertionOrderEndDate}
                                        placeholder="DateTimePicker"
                                        onChange={(value) => {
                                            formik.setFieldValue('CampaignInsertionOrderEndDate', moment(value).utcOffset(0, true).set({
                                                hour: 0,
                                                minute: 0,
                                                second: 0,
                                                millisecond: 0
                                            }).toDate() ?? null);

                                        }}
                                    />
                                </LocalizationProvider>
                                <FormHelperText error id="lastName-error">
                                    {formik.touched.CampaignInsertionOrderEndDate && formik.errors.CampaignInsertionOrderEndDate}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="fs-language">{ t('Agency')}</CustomFormLabel>
                                <Autocomplete
                                    fullWidth
                                    id="agencySel"
                                    disableClearable
                                    onChange={(event, value) => {
                                        setDefaultAgency(value);
                                        formik.setFieldValue('AgencyId', value.id ?? null)
                                        formik.setFieldValue('AgencyReferralEmails', [])
                                        setDefaultAgencyReferralEmail([]);

                                    }}
                                    value={defaultAgency}
                                    options={agencyList?.map((option) => ({
                                        id: option.AgencyId,
                                        label: option.AgencyName
                                    }))}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"

                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText error id="agencySel-error">
                                    {formik.touched.AgencyId && formik.errors.AgencyId}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="fs-language">{ t('Agency Referral')}</CustomFormLabel>
                                <Autocomplete
                                    fullWidth
                                    id="agencyReferralSel"
                                    multiple
                                    disableClearable
                                    onChange={(event, value) => {                                    
                                        formik.setFieldValue('AgencyReferralEmails', value.map(x => x.id) ?? []);
                                        setDefaultAgencyReferralEmail(value);
                                    }}
                                    value={defaultAgencyReferralEmail}
                                    options={formik.values.AgencyId ? agencyReferralList?.filter(x => x.AgencyId == formik.values.AgencyId).map((option) => ({
                                        id: option.Email,
                                        label: option.Email
                                    })) : []}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"

                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />

                                <FormHelperText error id="agencySel-error">
                                    {formik.touched.AgencyReferralEmails && formik.errors.AgencyReferralEmails}
                                </FormHelperText>
                            </Grid>
                           

                            <Grid item xs={12}>

                                <CustomFormLabel htmlFor="fs-language">{ t('Advertiser')}</CustomFormLabel>
                                <Autocomplete

                                    fullWidth
                                    id="advertiserSel"
                                    disableClearable
                                    onChange={(event, value) => {
                                        formik.setFieldValue('AdvertiserId', value.id ?? null);
                                        formik.setFieldValue('AdvertiserReferralEmails', null);
                                        setDefaultAdvertiserReferralEmails([]);
                                        setDefaultAdvertiser(value);
                                    }}
                                    value={defaultAdvertiser}
                                    options={advertiserList?.map((option) => ({
                                        id: option.AdvertiserId,
                                        label: option.AdvertiserName
                                    }))}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"

                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />

                                <FormHelperText error id="advertiserSel-error">
                                    {formik.touched.AdvertiserId && formik.errors.AdvertiserId}
                                </FormHelperText>


                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="fs-language">{t('Advertiser Referral')}</CustomFormLabel>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id="AdvertiserReferralEmailsSel"
                                    disableClearable
                                    onChange={(event, value) => {                       
                                        formik.setFieldValue('AdvertiserReferralEmails', value.map(x => x.id) ?? null)
                                        setDefaultAdvertiserReferralEmails(value);
                                    }}
                                    value={defaultAdvertiserReferralEmails}
                                    options={formik.values.AdvertiserId ? advertiserReferralList?.filter(x => x.AdvertiserId === formik.values.AdvertiserId)?.map((option) => ({
                                        id: option.Email,
                                        label: option.Email
                                    })) : []}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"

                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />

                                <FormHelperText error id="AdvertiserReferralEmails-error">
                                    {formik.touched.AdvertiserReferralEmails && formik.errors.AdvertiserReferralEmails}
                                </FormHelperText>
                            </Grid>
{/*                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="fs-language">{ t('Sales Account')}</CustomFormLabel>
                                <Autocomplete
                                    fullWidth
                                    id="saleSel"
                                    disableClearable
                                    onChange={(event, value) => {
                                        setDefaultSalesAccountEmail(value);
                                        formik.setFieldValue('SalesAccountEmail', value.id ?? null)
                                    }}
                                    value={defaultSalesAccountEmail}
                                    options={salesList?.map((option) => ({
                                        id: option.Email,
                                        label: option.Email
                                    }))}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"

                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />

                                <FormHelperText error id="saleSel-error">
                                    {formik.touched.SalesAccountEmail && formik.errors.SalesAccountEmail}
                                </FormHelperText>
                            </Grid>*/}
                            {Object.keys(source).length > 0 ?

                                <Grid item xs={12}>
                                    <CustomFormLabel htmlFor="fs-language">{ t('Traffic Account')}</CustomFormLabel>
                                    <Autocomplete
                                        fullWidth
                                        id="saleSel"
                                        disableClearable
                                        onChange={(event, value) => {
                                            setDefaultTrafficEmail(value);
                                            formik.setFieldValue('TrafficEmail', value.id ?? null)
                                        }}
                                        value={defaultTrafficEmail}
                                        options={trafficList?.map((option) => ({
                                            id: option.Email,
                                            label: option.Email
                                        }))}
                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                placeholder="Search input"
                                                aria-label="Search input"

                                                inputprops={{
                                                    ...params.inputprops,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />

                                    <FormHelperText error id="saleSel-error">
                                        {formik.touched.TrafficEmail && formik.errors.TrafficEmail}
                                    </FormHelperText>
                                </Grid> : ''
                            }
                

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderLocation" sx={{mt: 0}}>
                                   { t('Location Type')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="CampaignInsertionOrderLocation"
                                    name="CampaignInsertionOrderLocation"
                                    value={formik.values.CampaignInsertionOrderLocation}
                                    label="Age"
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderLocation && Boolean(formik.errors.CampaignInsertionOrderLocation)}
                                >
                                    <MenuItem value={'Hyper-Local'}>Hyper-Local</MenuItem>
                                    <MenuItem value={'Nazionale'}>Nazionale</MenuItem>
                                </Select>
                               
                                <FormHelperText error id="CampaignInsertionOrderLocation-error">
                                    {formik.touched.CampaignInsertionOrderLocation && formik.errors.CampaignInsertionOrderLocation}
                                </FormHelperText>
                            </Grid>
                            { formik.values.CampaignInsertionOrderLocation === 'Hyper-Local' ?

                           
                              <Grid item xs={12}>
                                  <CustomFormLabel htmlFor="CampaignInsertionOrderPdvNumber" sx={{mt: 0}}>
                                      { t('Numero punti vendita')}
                                  </CustomFormLabel>
                                  <CustomOutlinedInput
                                    id="CampaignInsertionOrderPdvNumber"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderPdvNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderPdvNumber && Boolean(formik.errors.CampaignInsertionOrderPdvNumber)}
                                  />
                                  <FormHelperText error id="CampaignInsertionOrderPdvNumber-error">
                                      {formik.touched.CampaignInsertionOrderPdvNumber && formik.errors.CampaignInsertionOrderPdvNumber}
                                  </FormHelperText>
                            </Grid> : ''}
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderState" sx={{mt: 0}}>
                                    { t('Location  ( same from pipedrive)')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                  id="CampaignInsertionOrderState"
                                  placeholder=""
                                  fullWidth
                                  value={formik.values.CampaignInsertionOrderState}
                                  onChange={formik.handleChange}
                                  error={formik.touched.CampaignInsertionOrderState && Boolean(formik.errors.CampaignInsertionOrderState)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderState-error">
                                    {formik.touched.CampaignInsertionOrderState && formik.errors.CampaignInsertionOrderState}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderDays" sx={{mt: 0}}>
                                   { t('Days')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderDays"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderDays}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderDays && Boolean(formik.errors.CampaignInsertionOrderDays)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderDays-error">
                                    {formik.touched.CampaignInsertionOrderDays && formik.errors.CampaignInsertionOrderDays}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderTelcoOperators" sx={{mt: 0}}>
                                   { t('Telco Operators')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderTelcoOperators"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderTelcoOperators}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderTelcoOperators && Boolean(formik.errors.CampaignInsertionOrderTelcoOperators)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderTelcoOperators-error">
                                    {formik.touched.CampaignInsertionOrderTelcoOperators && formik.errors.CampaignInsertionOrderTelcoOperators}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderOperatingSystems" sx={{mt: 0}}>
                                   { t('Operating Systems')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderOperatingSystems"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderOperatingSystems}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderOperatingSystems && Boolean(formik.errors.CampaignInsertionOrderOperatingSystems)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderOperatingSystems-error">
                                    {formik.touched.CampaignInsertionOrderOperatingSystems && formik.errors.CampaignInsertionOrderOperatingSystems}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderSalesInternalCode" sx={{mt: 0}}>
                                   { t('Sales Internal Code')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderSalesInternalCode"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderSalesInternalCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderSalesInternalCode && Boolean(formik.errors.CampaignInsertionOrderSalesInternalCode)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderSalesInternalCode-error">
                                    {formik.touched.CampaignInsertionOrderSalesInternalCode && formik.errors.CampaignInsertionOrderSalesInternalCode}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderDeviceTypes" sx={{mt: 0}}>
                                   { t('Device Types')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderDeviceTypes"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderDeviceTypes}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderDeviceTypes && Boolean(formik.errors.CampaignInsertionOrderDeviceTypes)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderDeviceTypes-error">
                                    {formik.touched.CampaignInsertionOrderDeviceTypes && formik.errors.CampaignInsertionOrderDeviceTypes}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderTarget" sx={{mt: 0}}>
                                   { t('Target detailed of the order')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderTarget"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderTarget}
                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderTarget && Boolean(formik.errors.CampaignInsertionOrderTarget)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderTarget-error">
                                    {formik.touched.CampaignInsertionOrderTarget && formik.errors.CampaignInsertionOrderTarget}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderTotalBudget" sx={{mt: 0}}>
                                   { t('Total Budget')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderTotalBudget"
                                    placeholder=""
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Euro size="20"/>
                                        </InputAdornment>
                                    }
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderTotalBudget}
                                    type={'number'}

                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderTotalBudget && Boolean(formik.errors.CampaignInsertionOrderTotalBudget)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderTotalBudget-error">
                                    {formik.touched.CampaignInsertionOrderTotalBudget && formik.errors.CampaignInsertionOrderTotalBudget}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <CustomFormLabel htmlFor="fs-language">
                                { t('Sell Type')}
                                </CustomFormLabel>
                                <Autocomplete
                                    fullWidth
                                    id="selltypeSel"
                                    disableClearable
                                    onChange={(event, value) => {
                                        setDefaultSellType(value);
                                        formik.setFieldValue('SellTypeId', value.id ?? null)
                                    }
                                    }
                                    value={defaultSellType}
                                    options={sellTypeList?.map((option) => ({
                                        id: option.SellTypeId,
                                        label: option.SellTypeName
                                    }))}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            placeholder="Search input"
                                            aria-label="Search input"
                                            inputprops={{
                                                ...params.inputprops,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText error id="selltypeSel-error">
                                    {formik.touched.SellTypeId && formik.errors.SellTypeId}
                                </FormHelperText>
                            </Grid>
                            {defaultSellType?.label === 'CPV' ?
                              <Grid item xs={12}>
                                  <CustomFormLabel htmlFor="CampaignInsertionOrderTelcoOperators" sx={{marginTop:"5px"}}>
                                      { t('Viewability %  ( 0 non tracciata, valore se tracciata) ')}
                                  </CustomFormLabel>
                                  <CustomOutlinedInput
                                    id="ViewabilityPercentage"
                                    placeholder=""
                                    fullWidth
                                    type={'number'}
                                    value={formik.values.ViewabilityPercentage}
                                    onChange={formik.handleChange}
                                    error={formik.touched.ViewabilityPercentage && Boolean(formik.errors.ViewabilityPercentage)}
                                  />
                                  <FormHelperText error id="ViewabilityPercentage-error">
                                      {formik.touched.ViewabilityPercentage && formik.errors.ViewabilityPercentage}
                                  </FormHelperText>
                              </Grid> : ''}
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="amount" sx={{mt: 0}}>
                                    { t('Sell Type Cost')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                  id="CampaignInsertionOrderSellTypeValue"
                                  placeholder=""
                                  fullWidth
                                  value={formik.values.CampaignInsertionOrderSellTypeValue}
                                  type={'number'}
                                  onChange={formik.handleChange}
                                  error={formik.touched.CampaignInsertionOrderSellTypeValue && Boolean(formik.errors.CampaignInsertionOrderSellTypeValue)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderSellTypeValue-error">
                                    {formik.touched.CampaignInsertionOrderSellTypeValue && formik.errors.CampaignInsertionOrderSellTypeValue}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormLabel htmlFor="CampaignInsertionOrderSellTypeValue" sx={{mt: 0}}>
                                    { t('Amount ( Volume KPI )')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomOutlinedInput
                                    id="CampaignInsertionOrderAmount"
                                    placeholder=""
                                    fullWidth
                                    value={formik.values.CampaignInsertionOrderAmount}
                                    type={'number'}

                                    onChange={formik.handleChange}
                                    error={formik.touched.CampaignInsertionOrderAmount && Boolean(formik.errors.CampaignInsertionOrderAmount)}
                                />
                                <FormHelperText error id="CampaignInsertionOrderTotalBudget-error">
                                    {formik.touched.CampaignInsertionOrderAmount && formik.errors.CampaignInsertionOrderAmount}
                                </FormHelperText>
                            </Grid>

                            <Grid items xs={12}>
                                <CustomFormLabel htmlFor="amount" sx={{mt: 0}}>
                                    { t('Note Campaign')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactQuill
                                    value={formik.values.CampaignInsertionOrderNote}
                                    onChange={(value) => {
                                        formik.setFieldValue('CampaignInsertionOrderNote', value ?? null);
                                    }}
                                    placeholder="Type here..."
                                />
                            </Grid>

                            <Grid items xs={12}>
                                <CustomFormLabel htmlFor="amount" sx={{mt: 0}}>
                                    { t('Invoice Note')}
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactQuill
                                    value={formik.values.CampaignInsertionOrderInvoiceNote}
                                    onChange={(value) => {
                                        formik.setFieldValue('CampaignInsertionOrderInvoiceNote', value ?? null);
                                    }}
                                    placeholder={t("Type here...")}
                                />
                            </Grid>


                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close}>{ t('Cancel')}</Button>
                        <LoadingButton
                          type={"submit"}
                          loading={isLoading}
                          disabled={false}
                          loadingIndicator={'Loading..'}
                          variant="contained"
                        >
                            {t('Submit')}
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default AddEditOrder;
